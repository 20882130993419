var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });

// src/user.utils.ts
function buildUserName(user) {
  return `${user.firstname} ${user.lastname}`;
}
__name(buildUserName, "buildUserName");
function nicknameOrName(user) {
  const { nickname, ...name } = user;
  return nickname || buildUserName(name);
}
__name(nicknameOrName, "nicknameOrName");
function nicknameOrFirstName({ nickname, firstname }) {
  return nickname || firstname;
}
__name(nicknameOrFirstName, "nicknameOrFirstName");
function buildUserNameWithNickname(user) {
  const { nickname, ...name } = user;
  const displayedNickname = nickname ? ` (${nickname})` : "";
  return `${buildUserName(name)}${displayedNickname}`;
}
__name(buildUserNameWithNickname, "buildUserNameWithNickname");
export {
  buildUserName,
  buildUserNameWithNickname,
  nicknameOrFirstName,
  nicknameOrName
};
